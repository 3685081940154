const upArrowClass = 'up-arrow'

const upArrowInit = () => {
  const upArrows = Array.from(document.querySelectorAll(`.${upArrowClass}`))

  if (upArrows.length > 0) {
    upArrows.forEach(upArrow => {
      upArrow.addEventListener('click', event => {
        event.preventDefault()

        if (window.scrollY > 0) {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }
      })
    })
  }
}

export default upArrowInit
