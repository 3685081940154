import CheckImport from './CheckImport'

export default class LoadInputFile extends CheckImport {
  getId() {
    return 'InputFile'
  }

  loadModule(found = [{ selector: '.input-file__container', moduleParams: {}, id: '' }]) {
    return import(/* webpackChunkName: "InputFile" */ '../modules/inputFile').then(
      this.process(found),
    )
  }
}
