// Only needed for "useBuiltIns": "entry"
// import '@babel/polyfill'
import 'lazysizes'

import axios from 'axios'
import { flatten } from 'lodash-es'

import loadPolyfills from './js/loadpolyfills'

import navigationInit from './js/components/navigation'
import stickyNavigationInit from './js/components/stickyNavigation'
import mobileMenuInit from './js/components/mobileMenu'
import hamburgerInit from './js/components/hamburger'
import upArrowInit from './js/components/upArrow'
import bottomBarInit from './js/components/bottomBar'
import { setLazyLoading } from './js/components/lazyloading'
import LoadPlyr from './js/partLoaders/loadPlyr'
import hashlinkScroll from './js/components/hashlink-scroll'
import ceFormAdditionalText from './js/components/ce-form-additional-text'
import LoadEmployerCalculator from './js/modules/employer-calculator/init/load-employer-calculator'
import LoadEmployeeCalculator from './js/modules/employee-calculator/init/load-employee-calculator'
import LoadFaqSearchModule from './js/modules/faq/search/init/LoadFaqSearchModule'
import LoadFaqResultsModule from './js/modules/faq/list/init/LoadFaqResultsModule'
// import LoadFormReference from './js/partLoaders/loadFormReference'
import LoadInputFile from './js/partLoaders/loadInputFile'
import LoadInputFileCheckbox from './js/partLoaders/loadInputFileCheckbox'
import { MppBi } from './js/modules/mpp-bi'

import './scss/main.scss'

const parts = [
  {
    LoadModule: new LoadPlyr(),
    loaderParams: [
      {
        selector: '.plyr',
        moduleParams: {},
        id: 'PlyrModule',
      },
    ],
  },
  {
    LoadModule: new LoadEmployerCalculator(),
    loaderParams: [
      {
        selector: '.employer-calculator__container',
        moduleParams: {},
        id: 'EmployerCalculator',
      },
    ],
  },
  {
    LoadModule: new LoadFaqSearchModule(),
    loaderParams: [
      {
        selector: '.faq-search__container',
        moduleParams: {},
        id: 'FaqSearch',
      },
    ],
  },
  {
    LoadModule: new LoadFaqResultsModule(),
    loaderParams: [
      {
        selector: '#faq__results',
        moduleParams: {},
        id: 'FaqResults',
      },
    ],
  },
  {
    LoadModule: new LoadEmployeeCalculator(),
    loaderParams: [
      {
        selector: '.employee-calculator__container',
        moduleParams: {},
        id: 'EmployeeCalculator',
      },
    ],
  },
  // {
  //   LoadModule: new LoadFormReference(),
  //   loaderParams: [
  //     {
  //       selector: '.js-ce-reference-form',
  //       moduleParams: {},
  //       id: 'FormReference',
  //     },
  //   ],
  // },
  {
    LoadModule: new LoadInputFile(),
    loaderParams: [
      {
        selector: '.input-file__container',
        moduleParams: {},
        id: 'InputFile',
      },
    ],
  },
  {
    LoadModule: new LoadInputFileCheckbox(),
    loaderParams: [
      {
        selector: '.input-file-checkbox__container',
        moduleParams: {},
        id: 'loadInputFileCheckbox',
      },
    ],
  },
]

loadPolyfills().then(async () => {
  /* eslint-disable no-unused-vars */
  window.axios = axios
  window.docReady = 'docReady'
  document.documentElement.classList.remove('no-js')

  /* eslint-disable no-unused-vars */
  const staticModules = []
  staticModules.push({
    type: 'MppBi',
    module: new MppBi(),
  })

  /* eslint-disable no-unused-vars */
  const modules = await [
    ...staticModules,
    ...flatten(await Promise.all(parts.map(part => part.LoadModule.loader(part.loaderParams)))),
  ]

  // Init components
  navigationInit()
  stickyNavigationInit()
  mobileMenuInit()
  hamburgerInit()
  setLazyLoading()
  upArrowInit()
  bottomBarInit()
  hashlinkScroll()
  ceFormAdditionalText()
})
