import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const mobileMenuId = 'mobile-menu'
const mobileMenuToggleId = 'mobile-menu-toggle'
const mobileMenuCheckboxClass = 'mobile-menu__checkbox'

const mobileMenuInit = () => {
  const mobileMenu = document.querySelector(`#${mobileMenuId}`)
  const mobileMenuToggle = document.querySelector(`#${mobileMenuToggleId}`)

  if (mobileMenuToggle && mobileMenu) {
    mobileMenuToggle.addEventListener('change', event => {
      event.preventDefault()

      disableBodyScroll(mobileMenu)

      if (!mobileMenuToggle.checked) {
        enableBodyScroll(mobileMenu)

        const mobileMenuCheckboxes = Array.from(
          mobileMenu.querySelectorAll(`.${mobileMenuCheckboxClass}`),
        )

        if (mobileMenuCheckboxes.length > 0) {
          mobileMenuCheckboxes.forEach(mobileMenuCheckbox => {
            mobileMenuCheckbox.checked = false
          })
        }
      }
    })
  }
}

export default mobileMenuInit
