import CheckImport from '../../../../partLoaders/CheckImport'

export default class LoadFaqResultsModule extends CheckImport {
  getId() {
    return 'FaqResults'
  }

  loadModule(found = [{ selector: '', moduleParams: {}, id: '' }]) {
    return import(/* webpackChunkName: "FaqSearch" */ './FaqResultsInit.js').then(
      this.process(found),
    )
  }
}
