import { delay } from 'lodash-es'

const navigationId = 'main-navigation'
const navigationHasCurrentItem = 'navigation--has-current-item'
const navigationItemClass = 'navigation__item'
const currentNavigationItemClass = 'navigation__item--current'
const submenuLinkClass = 'navigation-submenu__link'
const submenuItemClass = 'navigation-submenu__item'
const submenuLinkActiveClass = 'navigation-submenu__link--active'
const submenuItemActiveClass = 'navigation-submenu__item--active'
let navigationTimer
let currentNavigationItem

const navigationItemInit = ({ navigationItem, navigation }) => {
  if (navigationItem) {
    navigationItem.addEventListener(
      'mouseover',
      navigationItemMouseOverHandler({ navigationItem, navigation }),
    )
  }
}

const navigationItemMouseOverHandler = ({ navigationItem, navigation }) => event => {
  event.preventDefault()

  if (navigationTimer) {
    clearTimeout(navigationTimer)
  }

  navigationTimer = delay(function() {
    if (!currentNavigationItem) {
      navigation.classList.add(navigationHasCurrentItem)
    }

    if (currentNavigationItem && currentNavigationItem !== navigationItem) {
      currentNavigationItem.classList.remove(currentNavigationItemClass)
    }

    navigationItem.classList.add(currentNavigationItemClass)
    currentNavigationItem = navigationItem
  }, 200)
}

const navigationMouseLeaveHandler = ({ navigation }) => event => {
  event.preventDefault()

  if (navigationTimer) {
    clearTimeout(navigationTimer)
  }

  navigationTimer = delay(() => {
    if (currentNavigationItem) {
      navigation.classList.remove(navigationHasCurrentItem)
      currentNavigationItem.classList.remove(currentNavigationItemClass)
      currentNavigationItem = null
    }
  }, 200)
}

const navigationSubmenuInit = ({ navigationItem, submenuLinks, submenuItems }) => {
  if (submenuLinks.length > 0) {
    let activeSubmenuLink = 0

    // Reset to defaults
    navigationItem.addEventListener('mouseleave', event => {
      event.preventDefault()

      activeSubmenuLink = 0

      submenuLinks.forEach((link, index) => {
        if (index === 0) {
          link.classList.add(submenuLinkActiveClass)
        } else {
          link.classList.remove(submenuLinkActiveClass)
        }
      })

      submenuItems.forEach((item, index) => {
        if (index === 0) {
          item.classList.add(submenuItemActiveClass)
        } else {
          item.classList.remove(submenuItemActiveClass)
        }
      })
    })

    submenuLinks.forEach((link, index) => {
      link.addEventListener('mouseover', event => {
        event.preventDefault()

        link.classList.add(submenuLinkActiveClass)

        if (index !== activeSubmenuLink) {
          submenuLinks[activeSubmenuLink].classList.remove(submenuLinkActiveClass)
        }

        if (submenuItems.length > 0) {
          const submenuTargetId = link.dataset.navigationSubmenuId
          const submenuTarget = navigationItem.querySelector(
            `[data-navigation-submenu-target='${submenuTargetId}']`,
          )

          submenuItems[activeSubmenuLink].classList.remove(submenuItemActiveClass)

          submenuTarget.classList.add(submenuItemActiveClass)
        }

        activeSubmenuLink = index
      })
    })
  }
}

const navigationInit = () => {
  navigationImageLazyLoading()
  const navigation = document.querySelector(`#${navigationId}`)
  const navigationItems = Array.from(navigation.querySelectorAll(`.${navigationItemClass}`))

  navigation.addEventListener('mouseleave', navigationMouseLeaveHandler({ navigation }))

  navigationItems.forEach(item => {
    const submenuLinks = Array.from(item.querySelectorAll(`.${submenuLinkClass}`))
    const submenuItems = Array.from(item.querySelectorAll(`.${submenuItemClass}`))

    navigationItemInit({
      navigationItem: item,
      navigation,
    })

    navigationSubmenuInit({ navigationItem: item, submenuLinks, submenuItems })
  })
}

const setFirstImageOnNavItemHover = () => {
  const navigationItems = document.querySelectorAll('.navigation__item')
  navigationItems.forEach(navItem => {
    navItem.addEventListener('mouseover', ev => {
      if (navItem.querySelector('.navigation-submenu__image').src.split('#').length > 1) {
        navItem.querySelector('.navigation-submenu__image').src = navItem
          .querySelector('.navigation-submenu__image-wrapper')
          .getAttribute('data-src')
      }
    })
  })
}

const navigationImageLazyLoading = () => {
  setFirstImageOnNavItemHover()
  const navigationItems = document.querySelectorAll('.navigation__item')

  navigationItems.forEach(navItem => {
    navItem.querySelectorAll('.navigation-submenu__link').forEach(itemLink => {
      itemLink.addEventListener('mouseover', ev => {
        const submenuId = itemLink.getAttribute('data-navigation-submenu-id')
        const item = Array.from(navItem.querySelectorAll('.navigation-submenu__item')).filter(
          item => item.getAttribute('data-navigation-submenu-target') === submenuId,
        )[0]

        if (item.querySelector('.navigation-submenu__image').src.split('#').length > 1) {
          item.querySelector('.navigation-submenu__image').src = item
            .querySelector('.navigation-submenu__image-wrapper')
            .getAttribute('data-src')
        }
      })
    })
  })
}

export default navigationInit
