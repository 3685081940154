const hamburgerClass = 'hamburger'
const hamburgerLabelClass = 'hamburger-label'
const isActiveClass = 'is-active'

const hamburgerInit = () => {
  const hamburgers = Array.from(document.querySelectorAll(`.${hamburgerClass}`))

  if (hamburgers.length > 0) {
    hamburgers.forEach(hamburger => {
      hamburger.addEventListener('click', _ => {
        hamburger.classList.toggle(isActiveClass)

        const hamburgerAriaExpanded = hamburger.getAttribute('aria-expanded')
        if (hamburgerAriaExpanded !== null || hamburgerAriaExpanded !== undefined) {
          if (hamburgerAriaExpanded === 'false') {
            hamburger.setAttribute('aria-expanded', 'true')
          } else {
            hamburger.setAttribute('aria-expanded', 'false')
          }
        }

        const hamburgerLabel = hamburger.querySelector(`.${hamburgerLabelClass}`)
        if (hamburgerLabel) {
          hamburgerLabel.classList.toggle(isActiveClass)
        }
      })
    })
  }
}

export default hamburgerInit
