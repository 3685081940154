import { getSiblings } from '../helper/siblings'

const mobileMenuToggleId = 'mobile-menu-toggle'
const itemClass = 'bottom-bar__item'
const itemHiddenClass = 'bottom-bar__item--hidden'

const bottomBarInit = () => {
  const mobileMenuToggle = document.querySelector(`#${mobileMenuToggleId}`)

  if (mobileMenuToggle) {
    mobileMenuToggle.addEventListener('change', event => {
      event.preventDefault()

      const mobileMenuToggleParent = mobileMenuToggle.closest(`.${itemClass}`)

      const bottomBarSiblingItems = getSiblings(mobileMenuToggleParent)

      bottomBarSiblingItems.forEach(item => {
        item.classList.toggle(itemHiddenClass)
      })
    })
  }
}

export default bottomBarInit
