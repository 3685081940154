import CheckImport from '../../../partLoaders/CheckImport'

export default class LoadEmployeeCalculator extends CheckImport {
  getId() {
    return 'EmployeeCalculator'
  }

  loadModule(found = [{ selector: '', moduleParams: {}, id: '' }]) {
    return import(
      /* webpackChunkName: "EmployeeCalculator" */ './employee-calculator-init.js'
    ).then(this.process(found))
  }
}
