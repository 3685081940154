import { get } from 'lodash-es'

export class MppBi {
  static instance

  constructor() {
    if (MppBi.instance) {
      return MppBi.instance
    }

    this.dataLayer = window.dataLayer
    this.init()
    MppBi.instance = this
    return MppBi.instance
  }

  init() {
    window.dataLayer = window.dataLayer || []
    this.checkForLoadingEvents()
    this.addListeners()
  }

  addListeners() {
    document.addEventListener('bi', this.handleBiEvent.bind(this))
  }

  checkForLoadingEvents() {
    const loadingEvents = Array.from(document.querySelectorAll('[data-mppbi]'))
    loadingEvents.forEach(element => {
      let loadingEvent
      try {
        loadingEvent = JSON.parse(element.dataset.mppbi)
      } catch (e) {
        loadingEvent = { event: 'parseError' }
      }
      this.doEvent(loadingEvent)
    })
  }

  handleBiEvent(e) {
    this.doEvent(get(e, 'detail', { event: 'dataError' }))
  }

  doEvent({ event, data }) {
    switch (event) {
      case 'formSuccess':
        this.sendFormSuccess(data)
        break
      case 'parseError':
        break
      default:
        console.log(`No loading event action for "${event}"`)
        break
    }
  }

  sendFormSuccess({ formType, formId } = {}) {
    if (!formType || !formId) {
      return
    }
    this.addToDataLayer({ event: 'formSuccess', mppFormType: formType, mppFormId: formId })
  }

  addToDataLayer(item) {
    const data = { ...item }
    window.dataLayer.push(data)
  }
}
