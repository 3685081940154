import { throttled } from '../helper/throttling'

const metaNavigationId = 'meta-navigation'
const mainNavigationId = 'main-navigation'
const fixedActionLinkClass = 'fixed-action-link'
const fixedActionLinkHiddenClass = 'fixed-action-link--hidden'

const stickyNavigationInit = () => {
  const metaNavigation = document.querySelector(`#${metaNavigationId}`)
  const mainNavigation = document.querySelector(`#${mainNavigationId}`)
  const headerImageContainer = document.querySelector('.header__image-container')
  const fixedActionLinks = Array.from(document.querySelectorAll(`.${fixedActionLinkClass}`))
  const offsetTop = 50

  if (metaNavigation) {
    const metaNavigationHeight = metaNavigation.getBoundingClientRect().height
    let prevPosition = 0
    let metaNavigationIsVisible = true

    throttled(
      200,
      window.addEventListener('scroll', () => {
        if (window.scrollY < prevPosition || window.scrollY < offsetTop) {
          if (!metaNavigationIsVisible) {
            metaNavigation.style.transform = ''

            if (mainNavigation) {
              mainNavigation.style.transform = ''
            }

            if (headerImageContainer) {
              headerImageContainer.style.transform = ''
            }

            metaNavigationIsVisible = true
          }

          if (window.scrollY < offsetTop) {
            if (fixedActionLinks.length > 0) {
              fixedActionLinks.forEach(element => element.classList.add(fixedActionLinkHiddenClass))
            }
          }
        } else {
          if (metaNavigationIsVisible) {
            metaNavigation.style.transform = `translateY(-${metaNavigationHeight}px)`

            if (mainNavigation) {
              mainNavigation.style.transform = `translateY(-${metaNavigationHeight}px)`
            }

            if (headerImageContainer) {
              headerImageContainer.style.transform = `translateY(-${metaNavigationHeight}px)`
            }

            metaNavigationIsVisible = false
          }

          if (fixedActionLinks.length > 0) {
            fixedActionLinks.forEach(element =>
              element.classList.remove(fixedActionLinkHiddenClass),
            )
          }
        }

        prevPosition = window.scrollY
      }),
    )
  }
}

export default stickyNavigationInit
