import CheckImport from '../../../../partLoaders/CheckImport'

export default class LoadFaqSearchModule extends CheckImport {
  getId() {
    return 'FaqSearch'
  }

  loadModule(found = [{ selector: '', moduleParams: {}, id: '' }]) {
    return import(/* webpackChunkName: "FaqSearch" */ './FaqSearchInit.js').then(
      this.process(found),
    )
  }
}
