import CheckImport from '../../../partLoaders/CheckImport'

export default class LoadEmployerCalculator extends CheckImport {
  getId() {
    return 'AgRechner'
  }

  loadModule(found = [{ selector: '', moduleParams: {}, id: '' }]) {
    return import(
      /* webpackChunkName: "EmployerCalculator" */ './employer-calculator-init.js'
    ).then(this.process(found))
  }
}
