export function getSiblings(elem) {
  // Setup siblings array and get the first sibling
  const siblings = []
  let sibling = elem.parentNode.firstChild

  // Loop through each sibling and push to the array
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== elem) {
      siblings.push(sibling)
    }

    sibling = sibling.nextSibling
  }

  return Array.from(siblings)
}
