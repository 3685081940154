import CheckImport from './CheckImport'

export default class LoadPlyr extends CheckImport {
  getId() {
    return 'PlyrModule'
  }

  loadModule(found = [{ selector: '', moduleParams: {}, id: '' }]) {
    return import(/* webpackChunkName: "PlyrModule" */ '../modules/plyrPlayer').then(
      this.process(found),
    )
  }
}
