const getImages = () => {
  return document.querySelectorAll('img')
}

const getBackgroundImages = () => {
  return document.querySelectorAll('div.background-image--full')
}

const getIntersectionObersver = () => {
  return new IntersectionObserver(
    function(entries) {
      if (entries[0].isIntersecting === true) {
        if (!entries[0].target.src.includes('/fileadmin/') && entries[0].target.dataset.src) {
          entries[0].target.src = entries[0].target.dataset.src
        }
      }
    },
    { threshold: [0] },
  )
}

const getIntersectionObersverBackgroundImage = () => {
  return new IntersectionObserver(
    function(entries) {
      if (entries[0].isIntersecting === true) {
        if (!entries[0].target.style.backgroundImage && entries[0].target.dataset.src) {
          entries[0].target.style.backgroundImage = `url('${entries[0].target.dataset.src}')`
        }
      }
    },
    { threshold: [0] },
  )
}

const setSrc = () => {
  getImages().forEach(img => {
    getIntersectionObersver().observe(img)
  })

  getBackgroundImages().forEach(img => {
    getIntersectionObersverBackgroundImage().observe(img)
  })
}

export const setLazyLoading = () => {
  setSrc()
}
