import { get } from 'lodash-es'
const metaNavigationId = 'meta-navigation'
const mainNavigationId = 'main-navigation'

const hashlinkScroll = (selector = '.hashlink-scroll') => {
  const pageUrlHasHash = preventScrollToHaslinkOnLoad()
  const scrollToMeElement = document.querySelector('[data-scroll-to-me]')

  const hashLinks = Array.from(document.querySelectorAll(selector))

  const metaNavigation = document.querySelector(`#${metaNavigationId}`)
  const mainNavigation = document.querySelector(`#${mainNavigationId}`)
  const navHeight =
    (mainNavigation ? mainNavigation.getBoundingClientRect().height : 0) +
    (metaNavigation ? metaNavigation.getBoundingClientRect().height : 0) +
    10

  if (pageUrlHasHash) {
    scrollToHashLink({ navHeight, hash: window.location.hash })
  } else if (scrollToMeElement) {
    scrollToHashLink({ navHeight, hash: scrollToMeElement.dataset.scrollToMe })
  }

  // Custom click event for hash links
  hashLinks.forEach(link => {
    const hash = get(link.href.split('#'), 1)
    if (!hash) {
      return
    }
    link.addEventListener('click', hashlinkClickHandler(navHeight))
  })

  // Custom event to scroll to a hash link event object: { hash, event }
  document.addEventListener('scrollTo', handleScrollToEvent(navHeight))
}

const preventScrollToHaslinkOnLoad = () => {
  if (window.location.hash) {
    scroll(0, 0)
    setTimeout(function() {
      scroll(0, 0)
    }, 1)
    return true
  }
  return false
}

const hashlinkClickHandler = navHeight => e => {
  const href = get(e.target, 'href')
  const hash = href ? get(href.split('#'), 1) : null
  if (!hash) {
    return
  }
  scrollToHashLink({ navHeight, hash: `#${hash}` }, e)
}

const handleScrollToEvent = navHeight => ({ hash, event } = {}) => {
  scrollToHashLink({ navHeight, hash }, event)
}

const scrollToHashLink = ({ navHeight = 0, hash } = {}, e) => {
  const el = document.querySelector(hash)
  if (!el) {
    return
  }
  if (e) {
    e.preventDefault()
  }

  const elBounds = el.getBoundingClientRect()
  const position = elBounds.top + window.pageYOffset - navHeight
  if (e) {
    window.location.hash = hash
  }
  window.scroll({ top: position < 0 ? 0 : position, left: 0, behavior: 'smooth' })
}

export default hashlinkScroll
