// import isTouchDevice from 'is-touch-device'
export default function loadPolyfills() {
  const fillIntersectionObserver = () =>
    new Promise(resolve => {
      if (
        'IntersectionObserver' in window &&
        'IntersectionObserverEntry' in window &&
        'intersectionRatio' in window.IntersectionObserverEntry.prototype
      ) {
        // Minimal polyfill for Edge 15's lack of `isIntersecting`
        // See: https://github.com/w3c/IntersectionObserver/issues/211
        if (!('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
          Object.defineProperty(window.IntersectionObserverEntry.prototype, 'isIntersecting', {
            get: function() {
              return this.intersectionRatio > 0
            },
          })
        }
        resolve()
      } else {
        import('intersection-observer').then(module => {
          // Polyfill is an IIFE -> we do not need to call anything
          resolve()
        })
      }
    })

  const fillResizeObserver = () =>
    new Promise(resolve => {
      if ('ResizeObserver' in window === false) {
        import('@juggle/resize-observer').then(module => {
          window.ResizeObserver = module.ResizeObserver
          resolve()
        })
      } else {
        resolve()
      }
    })

  const fillCustomEvent = () =>
    new Promise(resolve => {
      if (typeof window === 'undefined') {
        resolve()
        return
      }

      try {
        var ce = new window.CustomEvent('test', { cancelable: true })
        ce.preventDefault()
        if (ce.defaultPrevented !== true) {
          // IE has problems with .preventDefault() on custom events
          // http://stackoverflow.com/questions/23349191
          throw new Error('Could not prevent default')
        }
        resolve()
      } catch (e) {
        var CustomEvent = function(event, params) {
          var evt, origPrevent
          params = params || {
            bubbles: false,
            cancelable: false,
            detail: undefined,
          }

          evt = document.createEvent('CustomEvent')
          evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
          origPrevent = evt.preventDefault
          evt.preventDefault = function() {
            origPrevent.call(this)
            try {
              Object.defineProperty(this, 'defaultPrevented', {
                get: function() {
                  return true
                },
              })
            } catch (e) {
              this.defaultPrevented = true
            }
          }
          return evt
        }

        CustomEvent.prototype = window.Event.prototype
        window.CustomEvent = CustomEvent // expose definition to window
        resolve()
      }
    })

  const smoothScroll = () =>
    new Promise(resolve => {
      if ('scrollBehavior' in document.documentElement.style) {
        resolve()
      } else {
        import('smoothscroll-polyfill').then(module => {
          module.polyfill()
          resolve()
        })
      }
    })

  const fillElementClosest = () =>
    new Promise(resolve => {
      if (window.Element.prototype && typeof window.Element.prototype.closest !== 'function') {
        import('element-closest').then(module => {
          module.default(window)
          resolve()
        })
      } else {
        resolve()
      }
    })

  // if (!isTouchDevice()) {
  //   try {
  //     document.body.classList.add('noTouchDevice')
  //   } catch (e) {
  //   }
  // } else {
  //   try {
  //     document.body.classList.add('touchDevice')
  //   } catch (e) {
  //   }
  // }

  return Promise.all([
    fillIntersectionObserver(),
    fillResizeObserver(),
    fillCustomEvent(),
    fillElementClosest(),
    smoothScroll(),
  ])
}
