const ceFormAdditionalText = () => {
  const buttons = Array.from(document.querySelectorAll('.ce-form__additional-text-button'))
  buttons.forEach(button => {
    button.addEventListener('click', handleClick)
  })
}

const handleClick = e => {
  e.preventDefault()
  const text = e.target.parentNode.querySelector('.ce-form__additional-text')
  if (!text) {
    return
  }
  e.target.classList.add('ce-form__additional-text-button--hide')
  text.classList.toggle('ce-form__additional-text--show')
}

export default ceFormAdditionalText
